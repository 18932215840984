import React from "react";
import { Title, Number, Value, Desc, SubValue, SubDesc } from "./styles";
import { Container, Row, Col, Table } from "react-bootstrap";
import {
    Window,
    MainNumber,
    WindowTitle,
    WindowSubTitle,
    WindowSubNumber,
    UpdateLabel,
    UpdateDiv,
    LateOrderWindowTitle,
} from "../Windows/styles";

function ValuesTableComp(props) {

    const getPercentValue = (value) => value ? ((value * 100).toFixed(2) + " %") : "Holiday"
    const getDecimalValue = (value) => value ? (value < 1000 ? "$" + value.toFixed(2) + "K" : "$" + (value / 1000).toFixed(3) + "M") : "Holiday"
    const getDollartPerHour = (value) => value ? ((value * 1000).toFixed(2) + " $/h") : "Holiday"

    const { data } = props

    return (

        <Table className={"ValuesTable"}>
            <tbody>
                <tr>
                    <td></td>
                    <td className={"ValuesTable__Label ValuesTable__Label-right"}>1 week</td>
                    <td className={"ValuesTable__Label ValuesTable__Label-right"}>4 week</td>
                    <td className={"ValuesTable__Label ValuesTable__Label-right"}>13 week</td>
                </tr>
                <tr>
                    <td className={"ValuesTable__Label"}>DL %</td>
                    <td>{getPercentValue(data?.DirectLaborPercentageWeekly)}</td>
                    <td>{getPercentValue(data?.DirectLaborPercentageMonthly)}</td>
                    <td>{getPercentValue(data?.DirectLaborPercentageQuarterly)}</td>
                </tr>
                <tr>
                    <td className={"ValuesTable__Label"}>DL Base %</td>
                    <td>{getPercentValue(data?.BaseDirectLaborPercentageWeekly)}</td>
                    <td>{getPercentValue(data?.BaseDirectLaborPercentageMonthly)}</td>
                    <td>{getPercentValue(data?.BaseDirectLaborPercentageQuarterly)}</td>
                </tr>
                <tr>
                    <td className={"ValuesTable__Label"}>Overtime Δ %</td>
                    <td>{getPercentValue(data?.OvertimeDeltaPercentageWeekly)}</td>
                    <td>{getPercentValue(data?.OvertimeDeltaPercentageMonthly)}</td>
                    <td>{getPercentValue(data?.OvertimeDeltaPercentageQuarterly)}</td>
                </tr>

                <tr>
                    <td className={"ValuesTable__Label"}>Vacay+Stat %</td>
                    <td>{getPercentValue(data?.VacayStatPercentageWeekly)}</td>
                    <td>{getPercentValue(data?.VacayStatPercentageMonthly)}</td>
                    <td>{getPercentValue(data?.VacayStatPercentageQuarterly)}</td>
                </tr>

                <tr>
                    <td className={"ValuesTable__Label"}>Bonus %</td>
                    <td>{getPercentValue(data?.BonusesPercentageWeekly)}</td>
                    <td>{getPercentValue(data?.BonusesPercentageMonthly)}</td>
                    <td>{getPercentValue(data?.BonusesPercentageQuarterly)}</td>
                </tr>

                <tr>
                    <td className={"ValuesTable__Label"}>Avg base rate</td>
                    <td>{getDollartPerHour(data?.AvgBaseRateWeekly)}</td>
                    <td>{getDollartPerHour(data?.AvgBaseRateMonthly)}</td>
                    <td>{getDollartPerHour(data?.AvgBaseRateQuarterly)}</td>
                </tr>

                <tr>
                    <td className={"ValuesTable__Label"}>Daily Bookings</td>
                    <td>{getDecimalValue(data?.BookingsDailyAverageWeekly)}</td>
                    <td>{getDecimalValue(data?.BookingsDailyAverageMonthly)}</td>
                    <td>{getDecimalValue(data?.BookingsDailyAverageQuarterly)}</td>
                </tr>

                <tr>
                    <td className={"ValuesTable__Label"}>Daily Production</td>
                    <td>{getDecimalValue(data?.ProductionCostDailyAverageWeekly)}</td>
                    <td>{getDecimalValue(data?.ProductionCostDailyAverageMonthly)}</td>
                    <td>{getDecimalValue(data?.ProductionCostDailyAverageQuarterly)}</td>
                </tr>
            </tbody>
        </Table>
    );
}

export { ValuesTableComp };