//import React from 'react';
import {
  useSalesGovernanceContext,
  SalesGovernanceData,
} from "../../Context/salesGovernanceContext";

//let ManufacturingContext = useManufacturingContext()
//<p>{ManufacturingContext.propData.BacklogCurrent} K</p>
export const WindowTitles = {
  BacklogCurrent: "Actual backlog",
  WeeksToZeroBacklogMonthlyProjection: "Weeks to zero backlog",
  DirectLaborPercentageMonthly: "DL % 4 weeks",
  DirectLaborPercentageQuarterly: "DL % 13 weeks",
  BaseDirectLaborPercentageMonthly: "DL Base % 4 weeks",
  BaseDirectLaborPercentageQuarterly: "DL Base % 13 weeks",
  BookingsDailyAverageMonthly: "Daily Bookings",
  ProductionCostDailyAverageMonthly: "Daily Production",
  BookingsDailyAverageQuarterly: "Daily Bookings",
  ProductionCostDailyAverageQuarterly: "Daily Production",
};

export const WindowSubTitles = {
  BacklogCurrent: "",
  WeeksToZeroBacklogMonthlyProjection: "",
  DirectLaborPercentageMonthly: "",
  DirectLaborPercentageQuarterly: "",
  BaseDirectLaborPercentageMonthly: "",
  BaseDirectLaborPercentageQuarterly: "",
  BookingsDailyAverageMonthly: "(4-Week Average)",
  ProductionCostDailyAverageMonthly: "(4-Week Average)",
  BookingsDailyAverageQuarterly: "(13-Week Average)",
  ProductionCostDailyAverageQuarterly: "(13-Week Average)",
};

interface WindowData {
  mainNumber: string;
  title: string;
  subtitle: string;
}

export const prepDate = (data: SalesGovernanceData | null) => {
  let result: Date = new Date();
  if (data == null || data == undefined) {
    return result;
  }
  result = data.CurrentWeekFriday;
  return result;
}

function handleNumber(number, role){ // Bookings Production
  if(role === "Mechanic")
    return (Math.round(number * 100) / 100).toFixed(1).toString();
  return String.prototype.concat((Math.round(number * 100) / 100).toFixed(2).toString(), "K");
}

export const prepWindowsData = (data: SalesGovernanceData | null, role: String) => {
  let result: Array<WindowData> = new Array();
  if (data == null || data == undefined) {
    return result;
  }

  let numberSymbolBefore = role === "Mechanic" ? "" : "$";
 
  result.push({
    mainNumber:
      data.BookingsDailyAverageMonthly == null
        ? "Holiday"
        : String.prototype.concat(numberSymbolBefore, handleNumber(data.BookingsDailyAverageMonthly, role)),
    title: WindowTitles.BookingsDailyAverageMonthly,
    subtitle: WindowSubTitles.BookingsDailyAverageMonthly
  });
  result.push({
    mainNumber:
      data.ProductionCostDailyAverageMonthly == null
        ? "Holiday"
        : String.prototype.concat(numberSymbolBefore, handleNumber(data.ProductionCostDailyAverageMonthly, role)),
    title: WindowTitles.ProductionCostDailyAverageMonthly,
    subtitle: WindowSubTitles.ProductionCostDailyAverageMonthly
  });
  result.push({
    mainNumber:
      data.BookingsDailyAverageQuarterly == null
        ? "Holiday"
        : String.prototype.concat(numberSymbolBefore, handleNumber(data.BookingsDailyAverageQuarterly, role)),
    title: WindowTitles.BookingsDailyAverageQuarterly,
    subtitle: WindowSubTitles.BookingsDailyAverageQuarterly
  });
  result.push({
    mainNumber:
      data.ProductionCostDailyAverageQuarterly == null
        ? "Holiday"
        : String.prototype.concat(numberSymbolBefore, handleNumber(data.ProductionCostDailyAverageQuarterly, role)),
    title: WindowTitles.ProductionCostDailyAverageQuarterly,
    subtitle: WindowSubTitles.ProductionCostDailyAverageQuarterly
  });
  return result;
};
